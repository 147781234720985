<template>
  <div>
    <c-search-box @enter="getAttachList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="LBLUSEFLAG"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5">
        <c-table
          ref="table"
          title="Excel upload 설정 목록"
          tableId="table"
          :columnSetting="false"
          :isFullScreen="false"
          :columns="grid.columns"
          :data="grid.data"
          @rowClick="rowClick"
          :isExcelDown="false"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn label="LBLSEARCH" icon="search" @btnClicked="getAttachList" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-7 col-lg-7 col-xl-7">
        <q-form ref="editForm">
          <c-card title="LBLDETAIL" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="newData" />
                <c-btn
                  v-if="editable && saveable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="excelUploadSetting"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveAttach"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="업무구분코드"
                  name="taskClassCd"
                  v-model="excelUploadSetting.taskClassCd">
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                <c-checkbox
                  :editable="editable && dataeditable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="excelUploadSetting.useFlag"
                />
              </div>
              <div class="col-12">
                <c-text
                  :editable="editable && dataeditable"
                  :required="true"
                  label="업무구분설명"
                  name="taskClassDesc"
                  v-model="excelUploadSetting.taskClassDesc">
                </c-text>
              </div>
              <div class="col-12">
                <c-upload 
                  :attachInfo="attachInfo"
                  :editable="editable && dataeditable">
                </c-upload>
              </div>
            </template>
          </c-card>
        </q-form>
      </div>
    </div>
  </div>
</template>

<script>
import { uid } from 'quasar';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'excel-upload-manage',
  data() {
    return { 
      searchParam: {
        useFlag: 'Y',
      },
      grid: {
        columns: [
          {
            name: 'taskClassCd',
            field: 'taskClassCd',
            label: '업무구분코드',
            align: 'center',
            sortable: true,
          },
          {
            name: 'taskClassDesc',
            field: 'taskClassDesc',
            label: '업무구분설명',
            align: 'left',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width: 70px',
            sortable: true,
          },
        ],
        data: [],
      },
      excelUploadSetting: {
        taskClassCd: '',  // 업무구분코드
        taskClassDesc: '',  // 업무구분설명
        useFlag: 'Y',  // 사용여부
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
      },
      attachInfo: {
        isSubmit: '', // 판정에서 데이터가 저장되어 키가 있는 상황임으로 사용하지 않음
        taskClassCd: 'EXCEL_UPLOAD_TEMPLATE',
        taskKey: '',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      editable: true,
      dataeditable: false,
      saveable: false,
      updateMode: false,
      isSave: false,
      saveType: 'POST',
      listUrl: '',
      detailUrl: '',
      checkUrl: '',
      saveUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = (this.$store.getters.user.userId === 'U000000001' ? this.$route.meta.editable : false);
      this.dataeditable = false;
      // api scope
      this.listUrl = selectConfig.sys.excelUpload.list.url;
      this.detailUrl = selectConfig.sys.excelUpload.get.url;
      this.checkUrl = selectConfig.sys.excelUpload.check.url;
      this.insertUrl = transactionConfig.sys.excelUpload.insert.url;
      this.updateUrl = transactionConfig.sys.excelUpload.update.url;
      // list scope
      this.getList();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1')[0];
      if (_table) {
        _table.classList.remove('bg-light-blue-1');
      }
    },
    getAttachList() {
      this.getList();
      this.rowRemoveSelect();
      this.reset();
    },
    getList() {
      this.saveable = false;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    rowClick(row) {
      // 상세조회
      this.saveable = true;
      this.updateMode = true;
      this.$http.url = this.$format(this.detailUrl, row.taskClassCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.$_.extend(this.excelUploadSetting, _result.data)
        this.attachInfo.taskKey = this.excelUploadSetting.taskClassCd

        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
        this.dataeditable = true;
      },);
    },
    newData() {
      this.saveUrl = this.insertUrl;
      this.saveType = 'POST';
      this.saveable = true;
      this.updateMode = true;
      this.dataeditable = true;
      this.rowRemoveSelect();
      Object.assign(this.$data.excelUploadSetting, this.$options.data().excelUploadSetting);
      this.attachInfo.taskKey = '';
    },
    reset() {
      this.saveable = false;
      this.updateMode = false;
      this.dataeditable = false;
      this.rowRemoveSelect();
      Object.assign(this.$data.excelUploadSetting, this.$options.data().excelUploadSetting);
      this.attachInfo.taskKey = '';
    },
    saveAttach() {
      this.$refs['editForm'].validate().then(_result => {
        // 중복체크
        if (_result) {
          this.$http.url = this.$format(this.checkUrl, this.excelUploadSetting.taskClassCd);
          this.$http.type = 'GET';
          this.$http.request((_result) => {
            if (this.updateMode || _result.data === 0) {
              window.getApp.$emit('CONFIRM', {
                title: 'LBLCONFIRM',
                message: 'MSGSAVE', // 저장하시겠습니까?
                // TODO : 필요시 추가하세요.
                type: 'info', // success / info / warning / error
                // 확인 callback 함수
                confirmCallback: () => {
                  this.excelUploadSetting.regUserId = this.$store.getters.user.userId
                  this.excelUploadSetting.chgUserId = this.$store.getters.user.userId

                  this.isSave = !this.isSave;
                },
                // 취소 callback 함수
                cancelCallback: () => {
                },
              });
            } else {
              window.getApp.$emit('ALERT', {
                title: 'LBLGUIDE', // 안내
                message:
                '동일한 시퀀스 아이디가 존재합니다.',
                type: 'warning', // success / info / warning / error
              });
              return;
            }
          },);
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.attachInfo.isSubmit = uid();
      this.getList();
      this.rowClick({ taskClassCd: this.excelUploadSetting.taskClassCd })
    },
  }
};
</script>
